@import url(https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* html {
  --cupertino-pane-background: inherit;
} */

/* cupertino-pane */
.pane {
  padding: 0px !important;
}
.cupertino-pane {
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
}

.scroll-touch {
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* .alice-carousel__wrapper {
  overflow: visible !important;
} */

.alice-carousel__dots {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.alice-carousel__dots-item{
  width: 6px !important;
  height: 6px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  background-color: #dadbdc !important;
}

.alice-carousel__dots-item.__active {
  width: 6px !important;
  height: 6px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  background-color: #4e99e9 !important;
}

.show-more-text {
  color: #7B8B9A !important;
  text-decoration: inherit !important;
}
.show-more-text:hover {
  text-decoration: none !important;
  -webkit-text-decoration-color: #7B8B9A !important;
          text-decoration-color: #7B8B9A !important;
}
.show-more-text:visited {
  text-decoration: none !important;
  -webkit-text-decoration-color: #7B8B9A !important;
          text-decoration-color: #7B8B9A !important;
}

.inherit-all {
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
  margin: inherit !important;
}

.hh2 {
  font-family: inherit !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 28px !important;
  letter-spacing: -0.07px !important;
  color: #283655 !important;
  margin: 40px !important;
}

.noselect {
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering:   -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
}

.input-standard {
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.05px;
  margin-bottom: 0px;
  width: 100%;
  margin-top: 0px;
  border: none;
  border-image-width: 1;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #425A70;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  box-sizing: border-box;
  border-color: rgba(67, 90, 111, 0.3);
  border-width: 1px;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);
}

.input-standard:focus {
  z-index: 2;
  outline: none;
  box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);
}

.input-standard:disabled {
  resize: none !important;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.05px;
  width: 100%;
  font-size: 14px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 100%;
  font-weight: 400;
  line-height: 20px;
  color: #66788A;
  padding-left: 13px;
  padding-right: 13px;
  border: none;
  cursor: not-allowed;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14);
  background-color: #F5F6F7;
}

.button-link {
  outline: none;
  width: inherit !important;
  text-decoration: none !important;
}
.button-link:hover {
  text-decoration: none !important;
}
.button-link:visited {
  text-decoration: none !important;
}

.text-flip {
  transform: scale(-1, -1);
}

.text-link {
  text-decoration: none !important;
}
.text-link:hover {
  text-decoration: none !important;
  -webkit-text-decoration-color: #7B8B9A !important;
          text-decoration-color: #7B8B9A !important;
}
.text-link:visited {
  text-decoration: none !important;
}

.inline-link {
  text-decoration: inherit !important;
  font-size: inherit !important;
}
.inline-link:hover {
  text-decoration: inherit !important;
  opacity: 0.85 !important;
}
.inline-link:visited {
  text-decoration: none !important;
}

.clickable-text {
  text-decoration: inherit !important;
}
.clickable-text:hover {
  cursor: pointer;
  opacity: 0.85 !important;
}
.clickable-text:active {
  color: #7B8B9A !important;
}
.clickable-text:visited {
  text-decoration: none !important;
}

.blue-link {
  text-decoration: inherit !important;
  color: #1070CA !important; /* #1070CA */
}
.blue-link:hover {
  text-decoration: none !important;
  -webkit-text-decoration-color: #1070CA !important;
          text-decoration-color: #1070CA !important;
}

.button-light {
  background: #F9F9FB !important;
  border-color: #7B8B9A !important;
  border-width: 1px !important;
  color: #7B8B9A !important;
  border-radius: 5px !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.button-light:hover {
  background: #E4E7EB !important;
  border-width: 1px !important;
  color: #283655 !important;
}

.button-blue {
  text-decoration: inherit;
  background: #3d8bd4 !important; /* #1070CA */
  color: #FFFFFF !important;
  border-radius: 5px !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.button-blue:hover {
  opacity: 0.85 !important;
}

.button-blue:disabled,
.button-blue[disabled]{
  opacity: 0.50 !important;
}

.button-green {
  text-decoration: inherit;
  background: #47B881 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.button-green:hover {
  opacity: 0.85 !important;
}
.button-green:disabled,
.button-green[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.button-green-empty {
  text-decoration: inherit;
  border-color: #47B881 !important;
  background: #47b8814f !important;
  border-radius: 50% !important;
  border-width: 2px !important;
  border-style: solid !important;
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.button-green-empty:hover {
  opacity: 0.85 !important;
}
.button-green-empty:disabled,
.button-green-empty[disabled]{
  border: 0px !important;
  background-color: #435a6f0f !important;
  opacity: 0.8 !important;
  color: #435a6fb3 !important;
}

.button-red-empty {
  text-decoration: inherit;
  border-color: #EC4C47 !important;
  background: #ec4c4749 !important;
  border-radius: 50% !important;
  border-width: 2px !important;
  border-style: solid !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.button-red-empty:hover {
  opacity: 0.85 !important;
}
.button-red-empty:disabled,
.button-red-empty[disabled]{
  border: 0px !important;
  background-color: #435a6f0f !important;
  opacity: 0.8 !important;
  color: #435a6fb3 !important;
}

.button-red {
  text-decoration: inherit;
  background: #EC4C47 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.button-red:hover {
  opacity: 0.85 !important;
}
.button-red:disabled,
.button-red[disabled]{
  /* border: 1px solid #999999 !important; */
  background-color: #7B8B9A !important;
  /* color: #666666 !important; */
}


.button-purple {
  text-decoration: inherit;
  background: #735DD0 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.button-purple:hover {
  opacity: 0.85 !important;
}
.button-purple:disabled,
.button-purple[disabled]{
  /* border: 1px solid #999999 !important; */
  background-color: #7B8B9A !important;
  /* color: #666666 !important; */
}



.input-currency {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.05px;
  width: 100%;
  font-size: 14px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 100%;
  font-weight: 400;
  color: #425A70;
  line-height: 20px;
  padding-left: 13px;
  padding-right: 13px;
  border: none;
  background-color: white;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);
}

.input-currency:focus {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.05px;
  width: 100%;
  font-size: 14px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 100%;
  font-weight: 400;
  color: #425A70;
  line-height: 20px;
  padding-left: 13px;
  padding-right: 13px;
  border: none;
  background-color: white;
  z-index: 2;
  outline: none;
  box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);
}
.input-currency:disabled {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.05px;
  width: 100%;
  font-size: 14px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 100%;
  font-weight: 400;
  line-height: 20px;
  color: #66788A;
  padding-left: 13px;
  padding-right: 13px;
  border: none;
  cursor: not-allowed;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14);
  background-color: #F5F6F7;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(67, 90, 111, 0.3) !important; 
  opacity: 1 !important; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(67, 90, 111, 0.3) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(67, 90, 111, 0.3) !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .mobile-hide {
    display: none;
  }
}

@media only screen and (max-width: 644px) {
  .mobile-show-pot {
    display: flex!important;
  }
}

@media only screen and (max-width: 644px) {
  .mobile-hide-pot {
    display: none !important;
  }
}

body {
  min-height: 100%;
  position: relative !important;
  margin: 0;
  padding: 0;
  /* background-color: #282c34; */
}

#root {
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.colourfulAnimation {
  background: #27c4f5
    linear-gradient(to right, #27c4f5, #a307ba, #fd8d32, #70c050, #27c4f5);
  background-size: 500%;
  animation: 2s linear infinite barprogress, 0.3s fadein;

  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
@keyframes barprogress {
  0% {
    background-position: 0% 0;
  }
  to {
    background-position: 125% 0;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.un_sdg_1 {
  text-decoration: inherit;
  background: #E5243B !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_1:hover {
  opacity: 0.85 !important;
}
.un_sdg_1:disabled,
.un_sdg_1[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_2 {
  text-decoration: inherit;
  background: #DDA83A !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_2:hover {
  opacity: 0.85 !important;
}
.un_sdg_2:disabled,
.un_sdg_2[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_3 {
  text-decoration: inherit;
  background: #4C9F38 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_3:hover {
  opacity: 0.85 !important;
}
.un_sdg_3:disabled,
.un_sdg_3[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_4 {
  text-decoration: inherit;
  background: #C5192D !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_4:hover {
  opacity: 0.85 !important;
}
.un_sdg_4:disabled,
.un_sdg_4[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_5 {
  text-decoration: inherit;
  background: #FF3A21 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_5:hover {
  opacity: 0.85 !important;
}
.un_sdg_5:disabled,
.un_sdg_5[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_6 {
  text-decoration: inherit;
  background: #26BDE2 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_6:hover {
  opacity: 0.85 !important;
}
.un_sdg_6:disabled,
.un_sdg_6[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_7 {
  text-decoration: inherit;
  background: #FCC30B !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_7:hover {
  opacity: 0.85 !important;
}
.un_sdg_7:disabled,
.un_sdg_7[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_8 {
  text-decoration: inherit;
  background: #A21942 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_8:hover {
  opacity: 0.85 !important;
}
.un_sdg_8:disabled,
.un_sdg_8[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_9 {
  text-decoration: inherit;
  background: #FD6925 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_9:hover {
  opacity: 0.85 !important;
}
.un_sdg_9:disabled,
.un_sdg_9[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_10 {
  text-decoration: inherit;
  background: #DD1367 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_10:hover {
  opacity: 0.85 !important;
}
.un_sdg_10:disabled,
.un_sdg_10[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_11 {
  text-decoration: inherit;
  background: #FD9D24 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_11:hover {
  opacity: 0.85 !important;
}
.un_sdg_11:disabled,
.un_sdg_11[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_12 {
  text-decoration: inherit;
  background: #BF8B2E !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_12:hover {
  opacity: 0.85 !important;
}
.un_sdg_12:disabled,
.un_sdg_12[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_13 {
  text-decoration: inherit;
  background: #3F7E44 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_13:hover {
  opacity: 0.85 !important;
}
.un_sdg_13:disabled,
.un_sdg_13[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_14 {
  text-decoration: inherit;
  background: #0A97D9 !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_14:hover {
  opacity: 0.85 !important;
}
.un_sdg_14:disabled,
.un_sdg_14[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_15 {
  text-decoration: inherit;
  background: #56C02B !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_15:hover {
  opacity: 0.85 !important;
}
.un_sdg_15:disabled,
.un_sdg_15[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_16 {
  text-decoration: inherit;
  background: #00689D !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_16:hover {
  opacity: 0.85 !important;
}
.un_sdg_16:disabled,
.un_sdg_16[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}

.un_sdg_17 {
  text-decoration: inherit;
  background: #19486A !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  border-style: none !important;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.un_sdg_17:hover {
  opacity: 0.85 !important;
}
.un_sdg_17:disabled,
.un_sdg_17[disabled]{
  /* border: 1px solid #999999 !important; */
  /* background-color: #7B8B9A !important; */
  /* color: #666666 !important; */
  opacity: 0.50 !important;
}
